<template>
  <leads-widget />
</template>

<script>
import LeadsWidget from '@/view/pages/leads/LeadsWidget.vue';
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "leads",
  components: {
    LeadsWidget
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Leads" }]);
  }
}
</script>

<style>

</style>